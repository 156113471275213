@import '../../../../../sass/variables.scss';

.footer__container {
    background-color: $black-color;
    bottom: 0px;
    left: 0px;
    right: 0px;
    position: sticky;
    padding: 1rem 0;
    z-index: 999;
    h2 {
        font-family: $sub-font;
        font-weight: 300;
        font-size: 1rem;
        text-align: center;
    }
}

@media (max-width: 575.98px) {
    .footer__container {
        position: initial;
    }
}
