@import '../../../../sass/variables.scss';

.home__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    section {
        padding-top: 125px;
        margin-bottom: calc($main-padding - 125px);
        max-width: 900px;

        &:first-child {
            padding-top: 0;
        }

        &:last-child {
            margin-bottom: 0px;
        }
    }

    .experience__section {
        width: 700px !important;
    }

    .contact__section {
        width: 600px !important;
    }
}

@media (max-width: 575.98px) {
    .home__container {
        section {
            width: 100%;
            margin-bottom: 85px;

            &:first-child {
                margin-bottom: 125px;
            }

            &:last-child {
                margin-bottom: 75px;
            }
        }

        .experience__section,
        .contact__section,
        .work__section {
            width: 100% !important;
        }
    }
}
