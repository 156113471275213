@import '../../../../../../sass/variables.scss';

.aboutme__container {
    .aboutme__content {
        display: flex;
        gap: 3rem;
        justify-content: space-between;

        .aboutme__text {
            width: 60%;
        }

        .aboutme__photo {
            width: 40%;
            .wrapper {
                background-color: $yellow-color;
                border-radius: 5%;
                height: 320px;
                position: relative;

                &:hover {
                    background-color: transparent;
                    img {
                        filter: grayscale(20%) contrast(1);
                        -webkit-filter: grayscale(20%) contrast(1);
                        -moz-filter: grayscale(20%) contrast(1);
                        mix-blend-mode: normal;
                    }
                }

                img {
                    border-radius: 5%;
                    filter: grayscale(100%) brightness(120%) contrast(1);
                    -webkit-filter: grayscale(100%) brightness(120%) contrast(1);
                    -moz-filter: grayscale(100%) brightness(120%) contrast(1);
                    mix-blend-mode: multiply;
                    width: 100%;
                    height: 320px;
                    object-fit: cover;
                }
            }
        }
    }
}

@media (max-width: 575.98px) {
    .aboutme__container {
        .aboutme__content {
            flex-direction: column-reverse;
            align-items: center;
            justify-content: center;

            .aboutme__text {
                width: 100%;
            }

            .aboutme__photo {
                display: none;
            }

            .aboutme__phone__photo {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                background-color: $yellow-color;
                width: 80%;
                img {
                    filter: grayscale(100%) contrast(1) brightness(120%);
                    mix-blend-mode: multiply;
                    width: 100%;
                }
            }
        }
    }
}
