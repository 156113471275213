@import '../../../../../../sass/variables.scss';

.contact__container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .title__section {
        color: #fff;
        text-align: center;
        font-size: 4rem;
    }

    p {
        text-align: center;
        font-family: $sub-font;
    }

    .contact__icon {
        margin-top: 1rem;
        font-size: 5rem;
        color: $yellow-color;
        padding: 1rem;

        &:hover {
            transform: scale(0.9);
            transition: all 0.5s;
            border: 5px solid $yellow-color;
            border-radius: 50%;
        }
    }
}

@media (max-width: 575.98px) {
    .contact__container {
        .title__section {
            font-size: 2.5rem;
        }
    }
}
