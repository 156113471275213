@import './sass/variables.scss';

.first__button {
    color: $yellow-color;
    background-color: transparent;
    border: 1px solid $yellow-color;
    padding: 10px 20px;
    font-family: $sub-font;

    &:hover {
        transition: all 0.3s;
        background-color: $yellow-transparent-color;
        cursor: pointer;
    }
}

.menu__number {
    color: $yellow-color;
    font-family: $sub-font;
    font-size: 1rem;
}

.title__section {
    font-family: $main-font;
    font-size: 2rem;
    margin-bottom: 3rem;

    span {
        margin-right: 0.5rem;
    }
}

.title__line {
    white-space: pre;
    display: flex;
    align-items: center;
    color: #fff;

    &::after {
        content: '';
        display: block;
        position: relative;
        width: 300px;
        height: 1px;
        margin-left: 20px;
        background-color: $white-color;
    }
}

.custom__list {
    list-style-type: '>';
    margin-left: 10px !important;
    margin-bottom: 1rem !important;
    padding-left: 0.5rem;

    &::marker {
        color: $yellow-color;
        font-weight: bold;
    }
}

.custom__icon {
    color: $white-color;
    &:hover {
        color: $yellow-color;
        cursor: pointer;
    }
}

@media (max-width: 575.98px) {
    .title__line {
        &::after {
            display: none;
        }
    }

    .title__section {
        font-size: 1.5rem;

        span {
            margin-right: 0.5rem;
        }
    }

    .menu__modal {
        width: 80% !important;
        height: 100vh;
    }

    .swal2-container {
        padding: 0 !important;
    }

    .swal2-close:focus {
        box-shadow: none !important;
    }
}
