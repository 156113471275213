@import '../../../sass/variables.scss';

.layout__container {
    background-color: $black-color;
    padding: 30px 60px;

    .layout__body__container {
        padding: $main-padding calc($main-padding / 1.5);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

@media (max-width: 575.98px) {
    .layout__container {
        padding: 10px 30px;

        .layout__body__container {
            padding: $main-padding / 2 0px 20px 0px !important;
            display: block;
        }
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .layout__container {
        padding: 10px 20px;

        .layout__body__container {
            padding: 100px;
            display: block;
        }
    }
}
