@import '../../../../../sass/variables.scss';

.header__container {
    align-items: center;
    display: flex;
    justify-content: space-between;
    position: sticky;
    top: 0px;
    padding: 30px 0;
    background-color: $black-color;
    z-index: 999999999;

    .logo__container {
        h1 {
            font-size: 3rem;
            font-weight: bold;
            color: #fff;
        }
    }

    .menu__container {
        display: flex;
        align-items: center;
        .menu__resume__container {
            align-items: center;
            display: flex;
        }
    }
}

@media (max-width: 575.98px) {
    .header__container {
        padding: 10px 0;
        z-index: 999;

        .logo__container {
            h1 {
                font-size: 2rem;
                color: $yellow-color;
            }
        }

        .menu__icon {
            font-size: 2rem;
            color: $yellow-color;
        }
    }
}
