@import '../../../../../../../sass/variables.scss';

.workexample__container {
    display: flex;
    margin-bottom: 7.5rem;

    // EEEEEVEEEEEEN
    &:nth-child(even) {
        flex-direction: row-reverse;

        .workexample__col__left {
            margin-left: -50px;
        }

        .workexample__col__right {
            .workexample__header {
                h2 {
                    text-align: left;
                }

                h3 {
                    text-align: left;
                }
            }

            .workexample__description {
                p {
                    text-align: left;
                }
            }

            .workexample__footer {
                .workexample__techs {
                    justify-content: flex-start;
                    h2 {
                        margin-left: 0;
                        margin-right: 1rem;
                    }
                }

                .workexample__links {
                    justify-content: flex-start;
                    a {
                        margin-left: 0;
                        margin-right: 1rem;
                    }
                }
            }
        }
    }

    .workexample__col__left {
        width: 50%;

        margin-right: -50px;

        .image {
            a {
                position: relative;

                &:hover {
                    .overlay {
                        &::before {
                            opacity: 0;
                        }
                    }

                    .workexample__image {
                        filter: none;
                        opacity: 1;
                        mix-blend-mode: normal;
                    }
                }

                .overlay {
                    &::before {
                        content: ''; // ::before and ::after both require content
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: $yellow-color;
                        opacity: 1;
                    }
                }

                .workexample__image {
                    position: relative;
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    opacity: 0.9;
                    filter: grayscale(100%) brightness(90%) contrast(1);
                    -webkit-filter: grayscale(100%) brightness(90%) contrast(1);
                    -moz-filter: grayscale(100%) brightness(90%) contrast(1);
                    mix-blend-mode: multiply;
                }
            }
        }
    }

    .workexample__col__right {
        padding-top: 3rem;

        width: 50%;
        z-index: 10;
        display: flex;
        flex-direction: column;
        align-content: center;

        .workexample__header {
            margin-bottom: 1.5rem;
            h2 {
                color: $yellow-color;
                font-size: 1rem;
                text-align: right;
                font-weight: 300;
                font-family: $sub-font;
                margin-bottom: 0.25rem;
            }

            h3 {
                color: #fff;
                font-size: 1.5rem;
                text-align: right;
            }
        }

        .workexample__description {
            background-color: #000000;
            padding: 1rem;
            p {
                text-align: right;
            }
        }
        .workexample__footer {
            margin-top: 1.5rem;

            .workexample__techs {
                display: flex;
                justify-content: flex-end;
                margin-bottom: 1rem;
                h2 {
                    font-size: 1rem;
                    font-weight: 300;
                    margin-left: 1rem;
                }
            }

            .workexample__links {
                display: flex;
                justify-content: flex-end;
                a {
                    margin-left: 1rem;
                }
            }
        }
    }
}

@media (max-width: 575.98px) {
    .workexample__container {
        margin-bottom: 3rem;

        .workexample__col__left {
            display: none;
        }

        .workexample__col__right {
            width: 100%;
            flex-wrap: wrap;
        }

        .workexample__header {
            h3 {
                font-size: 1.25rem !important;
            }
        }
    }
}
