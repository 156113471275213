@import '../../../../../../../sass/variables.scss';

.jobexample__container {
    h2 {
        font-size: 1.25rem;
        font-family: $main-font;
        font-weight: 500;
        color: #fff;
        margin-bottom: 0.5rem;
    }

    h3 {
        font-size: 1rem;
        font-family: $sub-font;
        font-weight: 300;
    }

    ul {
        margin-top: 1rem;

        li {
            font-family: $sub-font;
            font-weight: 300;
        }
    }
}
