@import '../../../../../../sass/variables.scss';

.menu__items__container {
    ul {
        display: flex;
        li {
            margin-right: 20px;
            font-size: 1rem;
            font-family: $sub-font;

            &:hover {
                color: $yellow-color;
                cursor: pointer;

                .menu__number {
                    font-size: 0.75rem;
                    transition: all 0.3s;
                }
            }
        }
    }
}

@media (max-width: 575.98px) {
    .menu__items__container {
        display: flex;
        flex-direction: column;
        gap: 3rem;
        ul {
            flex-direction: column;
            gap: 3rem;

            li {
                font-size: 2rem;
                display: flex;
                flex-direction: column;
                margin-right: 0;
                span {
                    font-size: 1.5rem;
                }
            }
        }

        .resume__container {
            a {
                button {
                    font-size: 1.5rem;
                    padding: 20px 30px;
                }
            }
        }
    }
}
