@import './sass/variables.scss';

@font-face {
    font-family: 'Circular';
    src: url('./assets/fonts/circular/CircularStd-Bold.otf') format('opentype');
    font-weight: bold;
}

@font-face {
    font-family: 'Circular';
    src: url('./assets/fonts/circular/CircularStd-Black.otf') format('opentype');
    font-weight: 900;
}

@font-face {
    font-family: 'Circular';
    src: url('./assets/fonts/circular/CircularStd-Book.otf') format('opentype');
    font-weight: normal;
}

@font-face {
    font-family: 'Circular';
    src: url('./assets/fonts/circular/CircularStd-Medium.otf') format('opentype');
    font-weight: 500;
}

@font-face {
    font-family: 'Circular';
    src: url('./assets/fonts/circular/CircularStd-Light.otf') format('opentype');
    font-weight: 100;
}

@font-face {
    font-family: 'Helvetica';
    src: url('./assets/fonts/helvetica/helvetica-thin.ttf') format('truetype');
    font-weight: 100;
}

@font-face {
    font-family: 'Helvetica';
    src: url('./assets/fonts/helvetica/helvetica-light.ttf') format('truetype');
    font-weight: 300;
}

@font-face {
    font-family: 'Helvetica';
    src: url('./assets/fonts/helvetica/helvetica-medium.ttf') format('truetype');
    font-weight: normal;
}

@font-face {
    font-family: 'Helvetica';
    src: url('./assets/fonts/helvetica/helvetica-bold.ttf') format('truetype');
    font-weight: bold;
}

@font-face {
    font-family: 'Helvetica';
    src: url('./assets/fonts/helvetica/helvetica-black.ttf') format('truetype');
    font-weight: 900;
}

body {
    font-family: 'Circular', 'Helvetica', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
        'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: relative;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
    color: $white-color;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

body {
    line-height: 1;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}

a {
    text-decoration: none;
}

p {
    line-height: 25px;
    font-size: $s-fontsize;
    font-weight: 300;
}

li {
    line-height: 25px;
}
