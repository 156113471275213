@import '../../../../../../../sass/variables.scss';
.verticaltab__container {
    .css-19kzrtu {
        padding: 0px !important;
        padding-left: 1rem !important;
    }

    .MuiTabs-vertical {
        .css-10d9dml-MuiTabs-indicator {
            background-color: $yellow-color !important;
            left: 0;
            right: initial;
        }

        button {
            color: $white-color;
            font-family: $sub-font;
            font-size: $s-fontsize;
            font-weight: 300;
            // text-transform: capitalize;
            text-transform: lowercase;
            align-items: flex-start;
        }

        button[aria-selected='true'] {
            color: $yellow-color;
            background-color: $yellow-transparent-color;
        }
    }
}

@media (max-width: 575.98px) {
    .css-19kzrtu {
        padding-left: 1rem !important;
    }
}
